<template>
  <div class="qna" :class="{skeleton: !state.loaded}">
    <div class="top">
      <span class="total font-sm">전체 {{ $lib.getNumberFormat(state.total) }}개</span>
      <div class="filter">
        <label :for="`${component.name}Search`" class="search">
          <Number :id="`${component.name}Search`" placeholder="프로젝트 번호를 입력해주세요" :value.sync="state.searchProjectSeq" :noComma="true" :enter="search"/>
          <div class="actions">
            <span class="remove" @click="removeCondition('projectSeq')" v-if="$route.query.projectSeq">&times;</span>
            <button class="btn" @click="search()">
              <i class="fa fa-search"></i>
            </button>
          </div>
        </label>
        <button class="btn btn-bordered-secondary" type="button" :id="`${component.name}Dropdown`" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="fa fa-filter"></i>
        </button>
        <div class="dropdown-menu" :aria-labelledby="`${component.name}Dropdown`">
          <div class="section" v-for="(c, idx1) in conditions" :key="idx1">
            <b class="title">{{ c.title }}</b>
            <span class="dropdown-item" v-for="(i, idx2) in c.items" :key="idx2" @click="setCondition(c.name, i.name)" :class="{active: $route.query[c.name] === i.name}">{{ i.title }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="conditions">
      <template v-for="(c, key, idx) in state.conditions">
        <div class="condition" :key="idx" v-if="c" title="삭제하기" @click="removeCondition(key)">
          <span>{{ getConditionName(key, c) }}</span>
          <span class="remove">&times;</span>
        </div>
      </template>
    </div>
    <div class="table-responsive" v-if="state.list.length">
      <table class="table table-hover">
        <thead>
        <tr>
          <th>
            <span>프로젝트 제목</span>
          </th>
          <th>
            <span>작성자</span>
          </th>
          <th>
            <span>내용</span>
          </th>
          <th>
            <span>작성 시간</span>
          </th>
          <th>
            <span>답변하기</span>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(q, idx) in state.list" :key="idx">
          <td class="project-name">
            <Anchor :href="`/${q.projectType}/${q.projectSeq}?tab=qna`" :title="q.projectName">
              <span class="badge" :class="q.projectType === 'invest' ? 'badge-purple': 'badge-point'">{{ q.projectType === "invest" ? "증권형" : "후원형" }}</span>
              <span>{{ q.projectName }}</span>
            </Anchor>
          </td>
          <td class="name">
            <span>{{ q.memberName }}</span>
          </td>
          <td :title="q.content">
            <span>{{ q.content }}</span></td>
          <td>
            <span>{{ $lib.getHumanReadableDate(q.createDate) }}</span>
          </td>
          <td>
            <a v-if="q.answererName" class="pointer-u color-anchor show ellipsis" @click="openEdit(q)" title="답변보기">답변 완료({{ q.answererName }})</a>
            <div v-else class="d-flex flex-column">
              <button class="btn btn-default answer" @click="requestAnswer(q)">진행자에게 알림톡 보내기</button>
              <button class="btn btn-default mt-1" @click="openReply(q)">답변하기</button>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      <PaginationJpa :pageable="state.pageable" :total="state.total" :change="load" className="btn-primary" v-if="state.total"/>
    </div>
    <NoData v-else/>
  </div>
</template>

<script>import {defineComponent, reactive, watch} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import Number from "@/components/Number";
import router from "@/scripts/router";
import lib from "@/scripts/lib";
import http from "@/scripts/http";
import Anchor from "@/components/Anchor";
import PaginationJpa from "@/components/PaginationJpa";
import store from "@/scripts/store";
import {httpError} from "@/scripts/httpError";
import NoData from "@/components/NoData";

function Component(initialize) {
  this.name = "pagesAdminBoardQna";
  this.initialize = initialize;
}

export default defineComponent({
  components: {NoData, PaginationJpa, Anchor, Number},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      load();
    });

    const state = reactive({
      list: [],
      loaded: false,
      total: 0,
      pageable: {
        page: 0,
        size: 0,
      },
      searchProjectSeq: null,
      conditions: {
        projectSeq: null,
        answered: "",
        projectType: "",
      }
    });

    const conditions = [{
      title: "프로젝트 유형",
      name: "projectType",
      items: [{
        name: "reward",
        title: "후원형",
      }, {
        name: "invest",
        title: "증권형",
      }]
    }, {
      title: "답변 여부",
      name: "answered",
      items: [{
        name: "N",
        title: "미완료",
      }, {
        name: "Y",
        title: "완료",
      },]
    },];

    const load = (num) => {
      state.list = [];
      state.total = 0;
      state.loaded = false;
      state.pageable = {
        page: 0,
        size: 0,
      };

      const args = {
        page: 0,
        size: 50,
      };

      const page = window.Number(router.app.$route.query.page) || 0;
      if (num !== undefined && num !== window.Number(page)) {
        return pushToRouter("page", num);
      }
      args.page = page;

      for (let i in router.app.$route.query) {
        if (state.conditions[i] && state.conditions[i] === router.app.$route.query[i]) {
          continue;
        }

        i !== "page" && (state.conditions[i] = router.app.$route.query[i]);
      }

      for (let i in state.conditions) {
        if (!router.app.$route.query[i]) {
          delete state.conditions[i];
        }

        args[i] = state.conditions[i];
      }

      for (let i = 0; i < 5; i += 1) {
        state.list.push({
          skeleton: true,
          projectSeq: "000000",
          projectType: "Wait",
          content: "Please wait a moment",
          projectName: "Please wait a moment",
          memberName: "Wait a moment",
          createDate: "0000-00-00 00:00:00",
          answerer: "Wait a moment",
        });
      }

      http.get("/api/admin/qnas", args).then(({data}) => {
        state.loaded = true;
        state.list = data.body.content;
        state.total = data.body.total;
        state.pageable = data.body.pageable;
      });
    };

    const pushToRouter = (key, value) => {
      const query = lib.getRenewed(router.app.$route.query);

      router.app.$route.query[key] !== value?.toString() && router.push({
        query: {
          ...query,
          [key]: value,
        }
      });
    };

    const setCondition = (key, value) => {
      if (!key || !value) {
        return;
      }

      pushToRouter("page", 0);

      if (!value || state.conditions[key] === value) {
        return removeCondition(key);
      }

      state.conditions[key] = value;
      pushToRouter(key, value);
    };

    const removeCondition = (key) => {
      const query = lib.getRenewed(router.app.$route.query);

      delete query[key];
      delete state.conditions[key];
      key === "projectSeq" && delete state.searchProjectSeq;

      pushToRouter("page", 0);
      router.push({query});
    };

    const getConditionName = (key, value) => {
      if (conditions.find(c => c.name === key)) {
        return conditions.find(f => f.name === key)?.items?.find(i => i.name === value)?.title;
      } else {
        return value;
      }
    };

    const search = () => {
      setCondition("projectSeq", state.searchProjectSeq);
    };

    const openReply = (question) => {
      const params = {
        groupNum: question.groupNum,
        projectType: question.projectType,
        projectSeq: question.projectSeq,
      };

      question.memberSeq && (params.createId = question.memberSeq);

      store.commit("openModal", {
        name: "Comment",
        params: {
          title: "답변하기",
          desc: `질문: ${question.content}`,
          ...params,
        },
        callback: `${component.name}.submit`
      });
    };

    const submit = (params) => {
      const args = {
        content: params.content,
        groupNum: params.groupNum,
        parentWriterSeq: params.createId,
      };

      http.post(`/api/maker/project/${params.projectType}/${params.projectSeq}/qna/reply`, args).then(() => {
        store.commit("setSwingMessage", "답변을 등록하였습니다.");
        store.commit("refresh");
      });
    };

    const openEdit = (comment) => {
      const params = {
        content: comment.answer,
      };
      comment.commentSeq && (params.commentSeq = comment.commentSeq);
      comment.projectType && (params.commentSeq = comment.projectType);
      comment.projectSeq && (params.projectSeq = comment.projectSeq);

      store.commit("openModal", {
        name: "Comment",
        params: {
          title: "답변하기",
          desc: `질문: ${comment.content}`,
          disabled: window.Number(comment.answererSeq) !== window.Number(store.state.account.memberSeq),
          commentSeq: comment.answerSeq,
          content: comment.answer,
          projectType: comment.projectType,
          projectSeq: comment.projectSeq,
        },
        callback: `${component.name}.put`,
      });
    };

    const put = ({commentSeq, content, projectType, projectSeq}) => {
      http.put(`/api/maker/project/${projectType}/${projectSeq}/qna/${commentSeq}/reply`, {content}).then(() => {
        store.commit("closeModal", {name: "Comment"});
        store.commit("refresh");
      }).catch(httpError());
    };

    const requestAnswer = (info) => {
      store.commit("confirm", {
        message: "진행자에게 답변을 요청하시겠습니까?",
        subMessage: "카카오톡으로 요청이 날아갑니다.",
        allow() {
          const args = {
            projectSeq: info.projectSeq,
            projectType: info.projectType,
          };
          http.post(`/api/admin/qna/${info.makerSeq}`, args).then(() => {
            store.commit("setSwingMessage", "진행자에게 답변을 요청하였습니다.");
          });
        }
      });
    };

    watch(() => router.app.$route.query, (next, prev) => {
      if (next.modals || prev.modals) {
        return;
      }

      load();
    });

    return {component, state, conditions, load, setCondition, getConditionName, removeCondition, search, openReply, submit, openEdit, put, requestAnswer};
  }
});
</script>

<style lang="scss" scoped>
.qna {
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $px20;

    .filter {
      display: flex;
      gap: $px10;

      .form-control, .btn, label {
        border: $px1 solid $colorBorder;
        font-size: $px12;
        height: $px43;
      }

      .search {
        position: relative;
        border: $px1 solid $colorBorder;
        border-radius: $px4;
        transition: border 0.18s;
        margin: 0;
        overflow: hidden;

        > .form-control {
          border: 0;
          font-size: $px12 !important;
          padding-right: $px60;
        }

        .actions {
          position: absolute;
          right: 0;
          top: 0;

          .remove {
            padding: $px10;
            cursor: pointer;
          }

          > .btn {
            border: 0;
            background: $colorBackground;

            &:hover {
              color: inherit;
            }
          }
        }

        &:focus-within {
          border-color: $colorPurple;
        }
      }

      .dropdown-menu {
        padding: $px10 $px15;
        min-width: auto;

        .section {
          .title {
            font-size: $px12;
            display: block;
          }

          .dropdown-item {
            border: $px1 solid $colorBorder;
            border-radius: $px100;
            cursor: pointer;
            display: inline-block;
            font-size: $px11;
            margin: $px4 $px4 $px4 0;
            padding: $px4 $px12;
            transition: 0.18s;
            width: auto;

            &.active {
              border-color: $colorPurple;
              color: $colorPurple;
            }

            &:hover {
              background: $colorBackground;
            }
          }

          &:not(:last-child) {
            margin-bottom: $px10;
          }
        }
      }
    }
  }

  .conditions {
    .condition {
      border: $px1 solid $colorPurple;
      border-radius: $px100;
      color: $colorPurple;
      cursor: pointer;
      display: inline-block;
      font-size: $px12;
      padding: $px2 $px12;
      margin: $px6 $px6 $px6 0;
      transition: background 0.18s;

      .remove {
        padding-left: $px6;
      }

      &:hover {
        background: $colorBackground;
      }
    }
  }

  .table-responsive {
    .table {
      tr {
        th {
          background: #f7f7f7;
        }

        th, td {
          max-width: $px150;
          overflow: hidden;
          text-overflow: ellipsis;
          vertical-align: middle;

          span {
            vertical-align: middle;

            &.badge {
              margin-right: $px4;
            }
          }

          .btn {
            width: 100%;
            padding: $px4 $px16;
            font-size: $px12;

            &.show {
              background-color: $colorBoxShadow;

              &:hover {
                color: #fff;
                background-color: #aaa;
              }
            }

            &.answer {
              background-color: #777;
              color: #fff;

              &:hover {
                background-color: #000;
              }
            }
          }

          &.project-name {
            max-width: $px170;
          }

          &.name {
            max-width: $px50;
          }
        }
      }
    }

    .pagination {
      margin-top: $px15;
    }
  }

  .no-data {
    padding: $px70 0;
  }

  &.skeleton {
    .top {
      span, label, .form-control, .btn {
        @include skeleton;
      }
    }

    .conditions .condition {
      @include skeleton;
    }

    .table-responsive .table tr {
      th, td {
        text-overflow: initial;

        span, .btn {
          @include skeleton;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .top {
      flex-direction: column;
      align-items: flex-start;
      gap: $px10;
    }
  }
}
</style>